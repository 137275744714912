<template>
  <div @click="closeOpenSearch">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-card title="User">
              <b-row>
                <b-col md="6">
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="User"
                          rules="required"
                        >
                          <b-form-group
                            label="User"
                            label-for="User"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              id="searchUser"
                              :state="orderData.user_id == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="searchUser"
                              placeholder="Search For Add user"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          @click="searchUserR(searchUser, 0)"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-alert
                        variant="warning"
                        style="margin: 0.438rem 1rem"
                        :show="noUser"
                      >
                        <div class="alert-body">No Result Found.</div>
                      </b-alert>
                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 35px;
                          max-height: 380px !important;
                          overflow-y: scroll;
                          overflow-x: hidden;
                          background-color: white;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                        "
                        v-if="userShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            @click="addUser(item)"
                            v-for="(item, index) in userSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <small style="padding-left: 10px"
                              >Email: {{ item.email }} - PhoneNumber:
                              {{ item.phone }}</small
                            >
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchUserR(searchUser, userCurrentPage)"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>

                <!-- phone Number 1 -->
                <b-col md="6">
                  <b-form-group label="Phone Number" label-for="Phone Number">
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required|egyptMobile"
                    >
                      <b-form-input
                        id="phone"
                        v-model="orderData.phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="015XXXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- User Name -->
                <b-col md="6">
                  <b-form-group label="User Name" label-for="User Name">
                    <validation-provider
                      #default="{ errors }"
                      name="User Name"
                      rules="required"
                    >
                      <b-form-input
                        id="Name"
                        v-model="orderData.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Products">
              <b-row>
                <b-col cols="12">
                  <div style="position: relative">
                    <b-row>
                      <b-col cols="7">
                        <b-form-input
                          id="searchText"
                          class="mt-2"
                          style="height: 37.45px"
                          type="search"
                          :state="orderData.variants.length == 0 ? false : true"
                          v-model="searchText"
                          placeholder="Search For Add Product"
                          @click="ShowVariantResultIfExists"
                        />
                      </b-col>
                      <b-col cols="2">
                        <b-button
                          @click="searchResultsubmit(searchText)"
                          class="mt-2"
                          variant="outline-primary"
                          >Search</b-button
                        >
                      </b-col>
                      <b-col cols="2" class="mt-2" v-if="noResult">
                        <b-alert variant="warning" style="" :show="noResult">
                          <div class="alert-body">No Result Found.</div>
                        </b-alert>
                      </b-col>

                      <b-col
                        class="mt-2"
                        style="
                          position: absolute;
                          z-index: 9999;
                          padding: 0.438rem 1rem;
                          top: 38px;
                          height: 380px !important;
                          overflow: scroll;
                          scroll-padding: 0px !important;
                          padding-right: 0;
                          background-color: #fff;
                        "
                        v-if="resultShow"
                        cols="12"
                      >
                        <b-list-group>
                          <b-list-group-item
                            v-b-modal.modal-prevent-closing
                            @click="
                              addProduct(
                                item.id,
                                item.name,
                                item.price,
                                item.quantity
                              )
                            "
                            v-for="(item, index) in productsSR"
                            :key="index"
                          >
                            <feather-icon icon="" class="mr-75" />
                            <p style="font-weight: 600">{{ item.name }}</p>
                            <div class="d-inline">
                              <small style="padding-left: 10px"
                                >Price: {{ item.price }}</small
                              >
                              <small style="padding-left: 10px"
                                >SKU: {{ item.sku }}</small
                              >
                            </div>
                          </b-list-group-item>
                          <infinite-loading
                            @infinite="searchResult"
                          ></infinite-loading>
                        </b-list-group>
                      </b-col>

                      <b-col cols="12">
                        <b-row>
                          <b-col
                            v-if="orderData.variants.length"
                            class="pt-1 pb-1 pr-5 pl-5"
                            cols="12"
                          >
                            <h2
                              v-if="orderData.variants.length"
                              class="mr-3 pt-2"
                            >
                              Items List:
                            </h2>
                            <!-- Row Loop -->
                            <div v-if="orderData.variants.length">
                              <b-row
                                v-for="(item, index) in orderData.variants"
                                :key="index"
                              >
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                                <b-col class="pl-5" cols="7">
                                  <h4>{{ item.name }}</h4>
                                  <small style="padding-left: 10px"
                                    >Price: {{ item.price }}</small
                                  >
                                </b-col>
                                <b-col md="2">
                                  <b-form-group
                                    label="Quantity"
                                    label-for="Quantity"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Quantity"
                                    >
                                      <b-form-input
                                        min="1"
                                        id="Quantity"
                                        type="number"
                                        v-if="item.max_qun"
                                        :max="item.max_qun"
                                        v-model="item.quantity"
                                        @input="recalcCost()"
                                        :state="
                                          errors.length > 0 ? false : null
                                        "
                                        placeholder="15"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <!-- Remove Button -->
                                <b-col md="2" class="mb-50">
                                  <b-button
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeItem(index)"
                                  >
                                    <feather-icon icon="XIcon" class="mr-25" />
                                    <span>Remove</span>
                                  </b-button>
                                </b-col>
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- <b-col cols="12" >
                        <b-card title="Bundles">
                            <b-row>
                                <b-col cols="12">
                                    <div style="position: relative">
                                        <b-row>
                                            <b-col cols="7">
                                                
                                                    <b-form-input id="searchText" class="mt-2" style="height:37.45px;" type="search" :state="orderData.variants.length == 0 ? false : true" v-model="searchText2" placeholder="Search For Add Bundle" />
                                                    
                                            </b-col>
                                            <b-col cols="2">
                                                <b-button @click="searchResultBundle(searchText2)" class="mt-2" variant="outline-primary">Search</b-button>
                                            </b-col>
                                            <b-alert variant="warning" style="margin: 0.438rem 1rem;" :show="noResultBund">
                                                <div class="alert-body">
                                                    No Result Found.
                                                </div>
                                            </b-alert>
                                            <b-col class="mt-2" style="position:absolute;z-index: 9999; padding: 0.438rem 1rem;top:35px;max-height: 380px!important;overflow-y: scroll; overflow-x: hidden;background-color:white;scroll-padding: 0px!important;padding-right: 0;" v-if="resultShowBund" cols="6">
                                                <b-list-group>
                                                    <b-list-group-item @click="addBundle(item.bundle_id, item.name, item.bundPrice, item.originalPrice)" v-for="(item,index) in productsSRBund">
                                                        <feather-icon icon="" class="mr-75" />
                                                        <p style="font-weight: 600;"> {{item.name}}</p>
                                                        <small style="padding-left: 10px;">Bundle Price: {{item.bundPrice}}</small>
                                                        <small style="padding-left: 10px;">Original Price: {{item.originalPrice}}</small>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-row>
                                                    <b-col v-if="orderData.bundles.length" class="pt-1 pb-1 pr-5 pl-5" cols="12">
                                                        <h2 v-if="orderData.bundles.length" class="mr-3 pt-2">Items List:</h2>
                                                       Row Loop -->
          <!-- <b-row v-if="orderData.bundles.length" v-for="(item, index) in orderData.bundles">
                                                            <b-col cols="12">
                                                                <hr>
                                                            </b-col>
                                                            <b-col class="pl-5" cols="7">
                                                                <h4>{{item.name}}</h4>
                                                                <small style="padding-left: 10px;">Bundle Price: {{item.bundPrice}}</small>
                                                                <small style="padding-left: 10px;">Original Price: {{item.originalPrice}}</small>
                                                            </b-col>
                                                            <b-col md="2">
                                                                <b-form-group label="Quantity" label-for="Quantity">
                                                                    <validation-provider #default="{ errors }" name="Quantity">
                                                                        <b-form-input min="1" id="Quantity" type="number" v-model="item.quantity" @input="recalcCost()" :state="errors.length > 0 ? false:null" placeholder="15" />
                                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                                    </validation-provider>
                                                                </b-form-group>
                                                            </b-col> -->
          <!-- Remove Button -->
          <!-- <b-col md="2" class="mb-50">
                                                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItemBundle(index)">
                                                                    <feather-icon icon="XIcon" class="mr-25" />
                                                                    <span>Remove</span>
                                                                </b-button>
                                                            </b-col>
                                                            <b-col cols="12">
                                                                <hr>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col> -->
          <b-col>
            <b-card title="Cost">
              <b-row>
                <!-- <b-col md='6'>
                                    <b-button id="toggle-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-prevent-closing variant="outline-primary">
                                        Add Coupon
                                    </b-button>
                                    <div v-if="couponData != null">
                                        <small v-if="couponActive" class="text-success">Your coupon has been worked</small>
                                        <small v-else class="text-danger">Invalid Coupon</small>
                                    </div>
                                </b-col> -->
                <b-col>
                  <!-- <p>Price: {{ cost }}</p> -->
                  <!-- <p>Discount: {{percentC}}%</p> -->
                  <!-- <p>Shipping: {{ shipping }}</p> -->
                  <p>Total: {{ total }}</p>
                </b-col>
              </b-row>
              <!-- add Coupon model -->
              <b-modal
                id="modal-prevent-closing"
                ref="my-modal"
                title="Submit Your Coupon"
                ok-title="Submit"
                cancel-variant="outline-secondary"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group
                    :state="couponState"
                    label="Coupon"
                    label-for="Coupon"
                    invalid-feedback="Coupon is required"
                  >
                    <b-form-input
                      id="Coupon"
                      v-model="couponSerial"
                      :state="couponState"
                      required
                    />
                  </b-form-group>
                </form>
              </b-modal>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { required, integer } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  mixins: [heightTransition],
  data() {
    return {
      shipComOptions: [],
      shipComSelect: null,
      couponSerial: '',
      couponData: null,
      couponState: null,
      cost: 0,
      noResult: false,
      noResultBund: false,
      resultShow: false,
      resultShowBund: false,
      userShow: false,
      searchText: '',
      searchText2: '',
      searchUser: '',
      noUser: false,
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      orderData: {
        phone: null,
        name: null,
        type: 'pos',
        coupon_id: null,
        user_id: null,
        variants: [],
        bundles: [],
      },
      userSelect: null,
      usersOptions: [],
      countrySelect: null,
      countryOptions: [],
      citySelect: null,
      cityOptions: [],
      areaSelect: null,
      areaOptions: [],
      couponSelect: null,
      couponsOptions: [],
      productsSR: [],
      productsSRBund: [],
      userSR: [],
      area: true,
      city: true,
      ship: true,
      startCPrice: '',
      endCPrice: '',
      percentC: 0,
      shipping: 0,
      total: 0,
      showerrorCoup: true,
      couponActive: false,
      addedProduct: {
        variant_id: null,
        quantity: null,
        name: null,
        price: null,
      },
      currentPage: 0,
      userCurrentPage: 0,
    }
  },
  watch: {
    cost: function () {
      if (this.couponSerial != '') {
        this.handleSubmit()
      }
    },
    couponSerial: function () {
      this.showerrorCoup = true
    },
  },
  created() {},
  methods: {
    // submit add form
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (
            this.orderData.bundles.length > 0 ||
            this.orderData.variants.length > 0
          ) {
            if (this.couponActive) {
              this.orderData.coupon_id = this.couponData.id
            } else {
              delete this.orderData.coupon_id
            }

            axios
              .post('orders/cashOnSite/create', this.orderData)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.push({
                  path: '/orders/OrderIndex/',
                })
              })
              .catch((err) => {
                console.log(err)
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          } else {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'Choose bundel or product first',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        }
      })
    },

    resetModal() {
      this.couponState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      axios
        .get('coupons/' + this.couponSerial + '/check/active/' + this.cost)
        .then((result) => {
          if (this.showerrorCoup) {
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Your coupon has been worked',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.showDismissibleAlert = false
          this.showerrorCoup = false
          this.$refs['my-modal'].hide()
          const data = result.data.data
          this.couponData = data
          this.startCPrice = data.startprice
          this.endCPrice = data.endprice
          this.percentC = data.percentage
          this.couponActive = true

          const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
          this.total = totalBeforShip + this.shipping
        })
        .catch((err) => {
          if (this.showerrorCoup) {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'invalid coupon',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.couponActive = false
          this.showerrorCoup = false
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    ShowVariantResultIfExists() {
      console.log('ss')
    },
    searchUserR(text, $page) {
      this.noUser = false
      this.userCurrentPage = $page + 1
      axios
        .get(
          'users/store-orders/20/sortBy-id-desc/' +
            text +
            '?page=' +
            this.userCurrentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.userSR = data
          if(this.userCurrentPage == 1){
            this.userSR = []
          }
          this.userSR = this.userSR.concat(data)
          // console.log(this.userSR)
          if (this.userSR.length > 0) {
            this.userShow = true
          } else {
            if(this.userCurrentPage == 1){
              this.userSR = []
              this.noUser = true
              this.userShow = false

            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResultsubmit($state) {
      this.noResult = false
      this.currentPage = 1
      axios
        .get(
          'orders/variants-pagi/20/sortBy-id-desc/' +
            this.searchText +
            '?page=' +
            this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data

          this.productsSR = []

          if (data.length > 0) {
            this.productsSR = data
            // for (let index in data) {
            //   if (data[index].name != null) {
            //     this.productsSR.push({
            //       id: data[index].id,
            //       name: data[index].name,
            //       price: data[index].sale_price,
            //       sku: data[index].sku,
            //       max_qun: data[index].quantity.toString(),
            //     })
            //   } else {
            //     this.productsSR.push({
            //       id: data[index].id,
            //       name: 'undefinded',
            //       price: data[index].sale_price,
            //       sku: data[index].sku,
            //       max_qun: data[index].quantity.toString(),
            //     })
            //   }
            // }

            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            $state.loaded()
          } else {
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            // $state.complete();
          }
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchResult($state) {
      this.noResult = false
      this.currentPage += 1
      axios
        .get(
          'orders/variants-pagi/20/sortBy-id-desc/' +
            this.searchText +
            '?page=' +
            this.currentPage
        )
        .then((result) => {
          const data = result.data.data.data
          // this.productsSR.length = 0
          // this.loadMore = false
          if (data.length > 0) {
            this.productsSR = this.productsSR.concat(data)
            // this.productsSR = data
            if (this.productsSR.length > 0) {
              this.resultShow = true
            } else {
              this.noResult = true
              this.resultShow = false
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    setData(id, name, price) {
      addedProductSubmit(id, name, price, 1)
    },

    addProduct(id, name, price, quen, quantity) {
      this.resultShow = false
      const found = this.orderData.variants.some((el) => el.variant_id === id)

      if (!found) {
        this.orderData.variants.push({
          variant_id: id,
          price: price,
          quantity: quen,
          name: name,
          max_qun: quantity,
        })
      } else {
        const index = this.orderData.variants.findIndex(
          (x) => x.variant_id === id
        )
        this.orderData.variants[index].quantity += quen
      }

      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
      this.productsSR.length = 0
    },
    searchResultBundle(text) {
      this.noResultBund = false

      axios
        .get('bundles/index/' + text)
        .then((result) => {
          const data = result.data.data.data

          this.productsSRBund.length = 0
          for (let index in data) {
            this.productsSRBund.push({
              bundle_id: data[index].id,
              name: data[index].title,
              bundPrice: data[index].bundle_price,
              originalPrice: data[index].original_price,
            })
          }

          if (this.productsSRBund.length > 0) {
            this.resultShowBund = true
          } else {
            this.noResultBund = true
            this.resultShowBund = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    addUser(user) {
      this.userShow = false
      this.searchUser = user.firstname + user.lastname
      this.orderData.user_id = user.id
      this.orderData.name = user.firstname + ' ' + user.lastname 
      this.orderData.phone = user.phone
      this.userSR.length = 0
    },
    addProduct(id, name, price, max_qun) {
      this.resultShow = false
      const found = this.orderData.variants.some((el) => el.variant_id === id)

      if (!found) {
        this.orderData.variants.push({
          variant_id: id,
          price: price,
          quantity: 1,
          name: name,
          max_qun: max_qun,
        })
      } else {
        const index = this.orderData.variants.findIndex(
          (x) => x.variant_id === id
        )
        this.orderData.variants[index].quantity += 1
      }

      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
      this.productsSR.length = 0
    },
    addBundle(id, name, bundPrice, originalPrice) {
      this.resultShowBund = false
      const found = this.orderData.bundles.some((el) => el.bundle_id === id)

      if (!found) {
        this.orderData.bundles.push({
          bundle_id: id,
          bundPrice: bundPrice,
          originalPrice: originalPrice,
          quantity: 1,
          name: name,
        })
      } else {
        const index = this.orderData.bundles.findIndex(
          (x) => x.bundle_id === id
        )
        this.orderData.bundles[index].quantity += 1
      }
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
      this.productsSRBund.length = 0
    },
    recalcCost() {
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
    },
    closeOpenSearch() {
      if (
        this.resultShow == true ||
        this.userShow == true ||
        this.resultShowBund == true
      ) {
        this.resultShow = false
        this.userShow = false
        this.resultShowBund = false
      }
    },
    removeItem(index) {
      this.orderData.variants.splice(index, 1)
      this.cost = 0
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
    },
    removeItemBundle(index) {
      this.orderData.bundles.splice(index, 1)
      this.cost = 0
      this.cost = 0
      if (this.orderData.variants.length > 0) {
        for (let index in this.orderData.variants) {
          const qun = parseInt(this.orderData.variants[index].quantity)
          const pri = parseFloat(this.orderData.variants[index].price)
          this.cost += qun * pri
        }
      }
      if (this.orderData.bundles.length > 0) {
        for (let index in this.orderData.bundles) {
          const qun = parseInt(this.orderData.bundles[index].quantity)
          const pri = parseFloat(this.orderData.bundles[index].bundPrice)
          this.cost += qun * pri
        }
      }
      const totalBeforShip = this.cost - this.cost * (this.percentC / 100)
      this.total = totalBeforShip + this.shipping
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
.repeater-form {
  transition: 0.35s height;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}
.app-auto-suggest {
  position: relative;
}
.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
}
.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}
.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}
.suggestion-current-selected {
  background-color: $body-bg;
  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
